import React, { createContext, useState } from 'react'
import { useEffect } from 'react'
import { ethers } from 'ethers'
import Web3Modal from 'web3modal'

export const PolyContext = createContext()

const PolyContextProvider = ({ children }) => {
  const connect = async () => {
    const providerOptions = {
      /* See Provider Options Section */
    }

    const web3Modal = new Web3Modal({
      network: 'matic', // optional
      cacheProvider: true, // optional
      providerOptions, // required
    })

    const provider = await web3Modal.connect()
    const providerEthers = new ethers.providers.Web3Provider(provider)
    const network = await providerEthers.getNetwork()
    const signer = providerEthers.getSigner()
    const address = await signer.getAddress()
    setProvider({ network, address, c: connect })
  }

  // This to allow already connected clients to not need to reconnect
  useEffect(() => {
    if (window?.ethereum) {
      const providerEthers = new ethers.providers.Web3Provider(
        window.ethereum,
        'any'
      )
      providerEthers.on('network', (newNetwork, oldNetwork) => {
        // When a Provider makes its initial connection, it emits a "network"
        // event with a null oldNetwork along with the newNetwork. So, if the
        // oldNetwork exists, it represents a changing network
        if (oldNetwork) window.location.reload()
      })
      providerEthers.getNetwork().then((network) => {
        const signer = providerEthers.getSigner()
        signer.getAddress().then((address) => {
          setProvider({ network, address, c: connect })
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [provider, setProvider] = useState({ c: connect })

  return (
    <PolyContext.Provider value={provider}>{children}</PolyContext.Provider>
  )
}

export default PolyContextProvider
