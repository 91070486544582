import { Router, Route, Set } from '@redwoodjs/router'
import HeaderFooterLayout from 'src/layouts/HeaderFooterLayout'

const Routes = () => {
  return (
    <Router>
      <Set wrap={HeaderFooterLayout}>
        <Route path="/" page={HomePage} name="home" />
      </Set>
      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
