/**
 * Main layout theme for the site
 */
import logo from '../../../images/bridges.png'
import { useState, useContext } from 'react'
import { ZilContext } from 'src/components/ContextProvider/ZilContextProvider'
import { PolyContext } from 'src/components/ContextProvider/PolyContextProvider'

export const TabContext = React.createContext()

const HeaderFooterLayout = ({ children }) => {
  const zilCtx = useContext(ZilContext)
  const polyCtx = useContext(PolyContext)
  var savedTab = localStorage.getItem('saved_tab')
  const [tab, setTab] = useState(savedTab)
  return (
    <div>
      <div className="container mx-auto">
        {/** Gradient **/}
        {/* <div className="block fixed inset-0 bg-gradient-to-b from-green-300	to-black -z-10" /> */}

        <div className="flex py-5">
          <div className="flex-1 flex justify-start">
            <h3 className="rounded-full h-10 w-10 ">
              <img src={logo} alt="Logo" />
            </h3>
          </div>
          <div className="flex-1 flex justify-center">
            <div className="rounded-xl py-1 px-1 bg-white flex flex-row justify-center font-semibold text-gray-600 ">
              <div
                className={
                  tab == 'ZilBridge'
                    ? 'rounded-xl py-2 px-4 bg-gray-200'
                    : 'rounded-xl py-2 px-4 hover:text-black'
                }
              >
                <button
                  onClick={() => {
                    setTab('ZilBridge')
                    localStorage.setItem('saved_tab', 'ZilBridge')
                  }}
                >
                  Zil -> PoS
                </button>
              </div>
              <div
                className={
                  tab == 'PolyBridge'
                    ? 'rounded-xl py-2 px-4 bg-gray-200'
                    : 'rounded-xl py-2 px-4 hover:text-black'
                }
              >
                <button
                  onClick={() => {
                    setTab('PolyBridge')
                    localStorage.setItem('saved_tab', 'PolyBridge')
                  }}
                >
                  PoS -> Zil
                </button>
              </div>
              <div className="rounded-xl py-2 px-4">
                <a
                  href="https://docs.traverse.finance/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Docs
                </a>
              </div>
              <div
                className={
                  tab == 'Transactions'
                    ? 'rounded-xl py-2 px-4 bg-gray-200'
                    : 'rounded-xl py-2 px-4 hover:text-black'
                }
              >
                <button
                  onClick={() => {
                    setTab('Transactions')
                    localStorage.setItem('saved_tab', 'Transactions')
                  }}
                >
                  Transactions
                </button>
              </div>
            </div>
          </div>
          <div className="flex-1 flex justify-end">
            {tab == 'Transactions' ? null : (
              <div className="rounded-xl py-1 px-1 flex flex-row justify-center font-mono text-sm text-white ">
                <p className="rounded-xl py-2 px-4">
                  {tab == 'ZilBridge'
                    ? zilCtx?.bech32
                      ? `Connected ${zilCtx.bech32.substring(
                          zilCtx.bech32.length - 9
                        )} zilliqa`
                      : ''
                    : polyCtx?.network?.chainId == 137
                    ? polyCtx?.address
                      ? `Connected ${polyCtx.address.substring(
                          polyCtx.address.length - 9
                        )} ${polyCtx.network.name}`
                      : ''
                    : ''}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <TabContext.Provider value={tab}>{children}</TabContext.Provider>
      <footer className="max-w-md mx-auto pb-6 pt-4 px-4 sm:max-w-3xl sm:pt-6 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="mt-10 border-t border-gray-300 pt-8 opacity-20"></div>
        <div className="m-2 text-base text-center text-white">
          <div className="text-white">{`v0.0.61`}</div>
        </div>
      </footer>
    </div>
  )
}

export default HeaderFooterLayout
