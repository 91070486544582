import React, { createContext, useState } from 'react'
import { useEffect } from 'react'
export const ZilContext = createContext()

const ZilContextProvider = ({ children }) => {
  const connectZilpay = async () => {
    if (window.zilPay) {
      await window.zilPay.wallet.connect()
      if (window.zilPay.wallet.isConnect) {
        setSenderAddress({
          ...window.zilPay.wallet.defaultAccount,
          c: connectZilpay,
        })
      }
    }
  }

  // This to allow already connected clients to not need to reconnect
  useEffect(() => {
    if (window.zilPay) {
      if (window.zilPay.wallet.isConnect) {
        window.zilPay.wallet.connect().then(() => {
          setSenderAddress({
            ...window.zilPay.wallet.defaultAccount,
            c: connectZilpay,
          })
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [senderAddress, setSenderAddress] = useState({ c: connectZilpay })

  return (
    <ZilContext.Provider value={senderAddress}>{children}</ZilContext.Provider>
  )
}

export default ZilContextProvider
